.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.header {
  width: 100%;
  height: 60px;
  background-color: #feb95f;
}
.navHeaderText {
  font-family: "Boing";
  font-size: 12px;
}

.bgImage {
  background-image: url("../src/bghead.svg");
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textAboveBgMed {
  color: white;

  font-family: "Boing";
}

/* .navbar-expand{
  height:  400px !important;
} */

.container {
  display: flex;
  overflow-x: auto;
}

.bg-iyiola {
  background-color: black !important;
  /* height: 110px; */
}
.textAboveBgLight {
  color: white;
  font-family: "ClanLight";
}

.navbar-brand {
  font-weight: bold !important;
}
.container {
  display: flex;
  overflow-x: auto;
}
.nav {
  background-color: transparent;
  position: sticky;
  color: white !important;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
}

.nav-logo {
  height: 60px;
  width: 60px;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 3rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-item {
  display: inline;
  margin-left: 2rem;
  color: white;
}

.nav-item:hover {
  color: white;
  cursor: pointer;
}

.section {
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}
.slide-container {
  width: 70%;
  margin: auto;
}

h3 {
  text-align: center;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.home-button {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  padding: 25px;
  border-radius: 8px;
  opacity: 0.9;
  transition: 0.3s;
}
.home-button:hover {
 opacity: 1;
}
